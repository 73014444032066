import type { AccessRule } from '@sevenrooms/core/domain'
import type { Cell, CellWithRowAndCol, Sheet } from '@fortune-sheet/core'

type CellValue = Cell['m']
type ColumnKey = keyof AccessRule | 'time'
interface Column {
  key: ColumnKey
  name: string
  hidden?: boolean
  submitted?: boolean
}

const DEFAULT_ROWS = 20
const COLUMNS: Column[] = [
  { key: 'id', name: 'ID', hidden: true, submitted: true },
  { key: 'isOverride', name: 'Is Override', hidden: true, submitted: true },
  { key: 'name', name: 'Name', submitted: true },
  { key: 'startDate', name: 'Start date', submitted: true },
  { key: 'endDate', name: 'End date', submitted: true },
  { key: 'date', name: 'Override Date', submitted: true },
  { key: 'time', name: 'Time' },
  { key: 'tableAreaSelectionDisplay', name: 'Seating Areas and Tables' },
  { key: 'publicTimeSlotDescription', name: 'Public time slot description', submitted: true },
  { key: 'publicLongFormDescription', name: 'Public long form description', submitted: true },
  { key: 'ccCost', name: 'CC cost', submitted: true },
  { key: 'ccPaymentRule', name: 'CC payment rule' },
  { key: 'ccChargeType', name: 'CC charge type' },
  { key: 'policy', name: 'Policy' },
  { key: 'cancellationPolicy', name: 'Cancellation policy' },
]

export function getSheetConfig(inputData: AccessRule[]): Sheet[] {
  const initialHiddenCols = COLUMNS.filter(({ hidden }) => hidden).length
  const headerRows: CellWithRowAndCol[] = COLUMNS.map(({ name }, index) => ({
    r: 0,
    c: index,
    v: {
      bl: 1, // bold text
      tb: '2', // text wrap
      v: name,
    },
  }))

  return [
    {
      name: 'Sheet1',
      celldata: headerRows.concat(toSpreadsheet(inputData)),
      config: {
        colhidden: { '0': 1, '1': 1 },
        columnlen: { [`${COLUMNS.length - 1}`]: 100 },
        rowReadOnly: { '0': 1 },
        rowlen: { '0': 70 },
      },
      filter_select: { row: [0, inputData.length], column: [initialHiddenCols, COLUMNS.length - 1] },
      row: Math.max(DEFAULT_ROWS, inputData.length + 1),
      column: COLUMNS.length,
    },
  ]
}

function toSpreadsheetValue(accessRule: AccessRule, key: ColumnKey) {
  if (key === 'startDate' || key === 'endDate' || key === 'date') {
    return accessRule[key]?.toIso()
  } else if (key === 'time') {
    if (accessRule.startTimeDisplay && accessRule.endTimeDisplay) {
      return `${accessRule.startTimeDisplay} - ${accessRule.endTimeDisplay}`
    } else if (accessRule.specificTimesDisplay?.length) {
      return accessRule.specificTimesDisplay.join(', ')
    }
    return undefined
  }

  return accessRule[key]?.toString()
}

export function toSpreadsheet(accessRules: AccessRule[]): CellWithRowAndCol[] {
  return accessRules.flatMap((accessRule, r) =>
    COLUMNS.map(({ key }, c) => ({
      r: r + 1, // offset header row
      c,
      v: {
        v: toSpreadsheetValue(accessRule, key),
        m: toSpreadsheetValue(accessRule, key),
      },
    }))
  )
}

function fromSpreadsheetValue(key: ColumnKey, value: CellValue) {
  if (key === 'ccCost') {
    return Number(value)
  } else if (key === 'isOverride') {
    return value === 'true'
  }

  return value
}

export function fromSpreadsheet(sheets: Sheet[]) {
  const rows = sheets[0]?.data?.map(row => row.map(cell => cell?.m)) || []
  return rows
    .filter(([id]) => id !== COLUMNS[0]?.name && Boolean(id))
    .map(row => Object.fromEntries(COLUMNS.map(({ key, submitted }, c) => (submitted ? [key, fromSpreadsheetValue(key, row[c])] : []))))
}
